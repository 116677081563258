<template>
	<!-- Start Template Root Div -->
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="w-1/4 text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">Sign Waiver</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<!--content-->
			<div
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-10"
			>
				<!--header-->
				<div
					class="hidden md:flex border-b border-solid border-gray-300 rounded-t"
				>
					<router-link
						:to="{
							name: 'SurferWaiverDisclaimer',
							query: this.$route.query
						}"
						class="flex-auto p-5 text-2xl font-semibold border-r"
					>
						1. Acknowledgement Of Risk And Waiver
					</router-link>
					<p
						class="flex-auto p-5 text-2xl text-gray-300 font-semibold border-r"
					>
						2. Sign Waiver
					</p>
					<p
						class="flex-auto p-5 text-2xl text-gray-300 font-semibold"
					>
						3. Watch Video
					</p>
				</div>

				<!--body-->
				<div class="relative p-6 flex-auto">
					<p class="mb-2 font-bold">
						Please Read Carefully Before Completing:
					</p>
					<p class="mb-2">
						All participants must complete this acknowledgement of risk and waiver before participating in
						surfing, body boarding or any other activity in the lake at The Wave. If you will be under 18
						years old on the day of your booked activity, the acknowledgement of risk and waiver must be
						completed by your parent or legal guardian. We will keep your completed waiver on file for 60
						days. Once the 60 day period has passed, if we update our safety or risk information, or if any of
						your circumstances change, you will be required to complete a new waiver. If there is anything
						that you do not understand please ask a member of The Wave Staff.
					</p>
					<h3>Acknowledgement Of Risk</h3>
					<p class="mb-2">
						Surfing and bodyboarding are physically challenging and carry risks that we cannot entirely
						eliminate, including damage to property, personal injury and in extremely rare circumstances,
						even death. You will reduce these risks by following The Wave Safety Rules set out below and all
						the instructions given to you by The Wave Staff.
					</p>
					<p class="mb-2">
						The Wave can produce a variety of waves of different speeds, shapes and sizes, which allows us
						to provide a safe environment for all abilities, from beginners to professionals. Information
						about the types of waves in each session and any specific risks or safety rules relating to the
						session are available on our website. The sessions are graded by ability. It is your
						responsibility to read the session descriptions and to ensure that you are competent to take
						part safely in your chosen session. If, during a session, we feel that you are not competent and
						may put yourself or others at risk, we reserve the right to remove you from the session without
						refund or compensation.
					</p>
					<p class="mb-2">
						We strongly advise that you begin with the easier sessions and work your way up. If you have any
						questions at all about the level of skill required for a session, please ask a member of The
						Wave Staff or contact our Customer Service Team.
					</p>
					<h3>Disclaimer</h3>
					<ul class="list-disc my-5 ml-5">
						<li>
							<p class="mb-2">In the absence of any negligence or other breach of duty by us:</p>
							<ul class="list-disc my-5 ml-5">
								<li class="mb-2">
									Your use of our equipment, facility and participation in any activity while at The Wave is
									entirely at your own risk.
								</li>
								<li class="mb-2">
									We are not responsible for any theft, damage, destruction or loss of your property or
									belongings while using our facilities.
								</li>
								<li class="mb-2">
									We accept no liability for any direct or indirect loss, damage or injury arising from or in
									connection with your participation in any activity at The Wave.
								</li>
							</ul>
						</li>
					</ul>
					<h3>The Wave Safety Rules:</h3>
					<ul class="list-disc my-5 ml-5">
						<li class="mb-2">
							You must behave sensibly and follow all safety instructions so as not to hurt or injure
							yourself or others.
						</li>
						<li class="mb-2">
							We can provide you with the equipment you need to safely enjoy your surf session. It is your
							responsibility to use all equipment safely and as directed.
						</li>
						<li class="mb-2">
							You must attend the safety briefing before participating in any activity in the lake and raise
							any concerns or lack of understanding with The Wave Staff.
						</li>
						<li class="mb-2">
							You must read the session descriptions and respect the ability gradings, even if you have
							taken part in a session before. We make ongoing changes and improvements to our sessions, so
							they may have changed since your last visit. You must not take part in a session that is
							beyond your current level of ability.
						</li>
						<li class="mb-2">
							You must be aware of the varying ability of other people in the lake. Be friendly, courteous
							and respectful of other people. Poor conduct will not be tolerated.
						</li>
						<li class="mb-2">
							We strongly recommend that you do not participate in any surfing session at The Wave if you
							are pregnant as it may cause harm to you or your unborn child. If you are pregnant and choose
							to participate, you do so at your own risk.
						</li>
						<li class="mb-2">
							You must not participate if you are under the influence of alcohol or non-prescription drugs
							or if you are taking any medication that may impair your physical ability.
						</li>
						<li class="mb-2">
							You must follow all safety warnings or instructions displayed or given to you by a member of
							The Wave Staff. Please ask a member of The Wave Staff if you are unclear.
						</li>
						<li class="mb-2">You must be able to swim at least 25 metres unaided.</li>
						<li class="mb-2">
							We are not qualified to express an opinion that you are fit to participate safely. If you
							suffer from any medical condition, illness or injury which may affect your ability to
							participate in surfing, bodyboarding or any other activities at The Wave, we recommend you
							obtain professional or specialist advice from your doctor and contact our Customer Services
							Team to discuss your specific requirements.
						</li>
						<li class="mb-2">
							You agree to comply fully with the rules set out in our Terms and Conditions of Booking and
							Access.
						</li>
						<li class="mb-2 font-bold">
							If your ability to participate safely is affected or changes at any time after completing the
							waiver you must notify a member of The Wave Staff.
						</li>
					</ul>
					<h3>Photography</h3>
					<p class="mb-2">
						We may take photographs or recordings during your visit to The Wave where you may be captured.
						You agree to the publication of such photographs and recordings, and their use by us and those
						authorised by us, in any way which we may see fit. This includes making photographs or
						recordings of surf or bodyboarding sessions available for purchase by you, via download from our
						website. If you do not wish to be filmed or photographed during your visit, including during
						your surf or bodyboarding session, please let a member of The Wave Staff know in advance.
					</p>
					<hr />
				</div>

				<!-- Footer -->
				<div class="w-full flex mb-12 justify-center items-center">
					<router-link
						:to="{
							name: 'SurferWaiverSign',
							query: this.$route.query
						}"
						class="w-9/12 md:w-6/12 lg:w-3/12 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					>
						Accept and Continue
					</router-link>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			WaveLogo
		},
		data: () => ({
			validEmail: false,
			emailAddress: "",
			waivers: [],
			reservation: undefined
		}),
		created() {
			this.checkData();
		},
		methods: {
			// Vuex Mapped Actions from reservations Namespace:
			...mapActions({
				getReservation: "reservations/fetchReservation"
			}),

			async checkData() {
				// Setup Plugin & Params
				await this.setupData();

				// Re-append Route & Redirect if missing query params
				if (
					!this.validEmail ||
					this.reservation.getWaiversSignable().length < 1
				) {
					this.$router.push({
						name: "SurferWaiver",
						query: this.$route.query
					});
					return;
				}

				// Re-append Route & Redirect if none-selected waiver
				if (
					this.reservation.waiversAdults.length > 1 &&
					this.reservation.selectedFirstName === ""
				) {
					this.$router.push({
						name: "SurferWaiverSelect",
						query: this.$route.query
					});
					return;
				}
			},
			async setupData() {
				// Call Plugin
				if (this.$route.query.emailAddress) {
					// Parse Email Address
					this.emailAddress = this.$route.query.emailAddress
						? this.$route.query.emailAddress
								.replace("..", "@")
								.toLowerCase()
						: "";

					// Get Waivers
					this.reservation = await this.getReservation(
						this.emailAddress
					);
					this.waivers = this.reservation.waivers;

					// Check Email Addresses
					if (
						this.emailAddress ==
						this.reservation.primaryEmailAddress
					)
						this.validEmail = true;
				}
			}
		}
	};
</script>
